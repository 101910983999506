import React, { useState, useEffect, useRef } from "react";
import detailsClasses from "./details.module.css";
import { NavLink } from "react-router-dom";
import { Services } from "../../../Services";
import { useSearchParams } from "react-router-dom";
import Layout from "../../../Components/Layout";
import { update_batchDuration } from "../../../Services";
import { MdEdit } from "react-icons/md";

import { formatNumberToIndian } from "Util/Util";
import Mainloader from "../../../Components/loader/loader";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import swal from "sweetalert";
import { Error_Dark, SuccessSwal } from "../../../Util/Toast";
import { AdminCancelType } from "../../../Statusconfig";
import Skeleton from "react-loading-skeleton";
import Reschedule from "../reschedule/reschedule";
import { AccessKeys } from "../../../Routes/config";
import { AccessControl } from "../../../Util/AccessControl";
import Logs from "Components/ActivityLog/log";
import CancelClass from "../CancelClass/cancel";
import DurationEdit from "../Duration/report";
import moment from "moment";

import Repeat from "../Repeat/repeat";
import Report from "../Report/report";
import { FaEdit } from "react-icons/fa";
import CompleteModal from "./Completeclass/complete";
function Details() {
    const Navigate = useNavigate();
    const inputref = useRef();
    const [params] = useSearchParams();
    const [rescheduleModal, setRescheduleModal] = useState(false);
    const [CancelModal, setCancelModal] = useState(false);
    const [editclass, setEditClass] = useState(false);
    const [completeClass, setCompleteClass] = useState(false);
    const [AttendanceLoad, setAttendenceLoad] = useState(false);
    const [data, setData] = useState({});
    const [modal, setModal] = useState(false);
    const [ShowReport, setShowReport] = useState(null);
    const [loading, setLoading] = useState(true);
    const [notifications, setNotifications] = useState({
        notifyData: [],
        load: true,
    });
    const [expandedRows, setExpandedRows] = useState([]);
    const adminData = JSON.parse(localStorage.getItem("admindata"));
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const getNotifications = () => {
        Services.NotificationsByscheduleClassId(
            "GET",
            null,
            token,
            adminData?.Type,
            adminData?.AdminUserID,
            params.get("scheduleID")
        )
            .then((response) => {
                console.log(response);
                setTimeout(() => {}, 200);
                if (response.Status === 1) {
                    setNotifications((prev) => {
                        return {
                            load: false,
                            notifyData: response.ScheduleClassNotifications,
                        };
                    });
                } else if (response.Status === 0) {
                    Error(response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };

    function getscheduledClasses() {
        Services.getScheduledClassesDetails(
            "GET",
            null,
            token,
            params.get("scheduleID")
        )
            .then((response) => {
                if (response.Status === 1) {
                    setData(response.ScheduleClasess);
                    setTimeout(() => {
                        setLoading(false);
                    }, 200);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
                alert("something went wrong try again");
            });
    }
    useEffect(() => {
        getscheduledClasses();
        getNotifications();
        // eslint-disable-next-line
    }, [params.get("scheduleID")]);
    const loginData = JSON.parse(localStorage.getItem("admindata"));
    async function cancelClassfunc(formdata) {
        let date = new Date(data.Scheduled_StartDate);
        let TutorRms = [
            data.SecondShift_RM_Morning,
            data.SecondShift_RM_Evening,
            data.Lead_RM,
            data.Buddy_RM,
        ].filter((item) => item != 0);
        let body = JSON.stringify({
            ...formdata,
            ZoomMeetingID: data.ZoomMeetingID,
            AdminUserType: loginData.Type,
            BatchID: data.BatchID,
            Duration: data?.Duration,
            ClassDuration: data?.ClassDuration,
            FeePerHour: data?.FeePerHour,
            BatchName: data.BatchName,
            BatchSize: data.BatchSize,
            CancelledByID: adminData?.AdminUserID,
            CancelledBy: AdminCancelType,
            Students: data?.Students,
            TutorEmailID: data?.TutorEmailID,
            TutorRms,
            Scheduled_StartTime: data.Scheduled_StartTime,
            TutorName: data.TutorName,
            TutorID: data.TutorID,
            ScheduleCreatedBy: data.ScheduleCreatedBy,
            ScheduleCreatedByType: data.ScheduleCreatedByType,
            Scheduled_StartDate: `${date.getFullYear()}-${String(
                date.getMonth() + 1
            ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`,
        });

        try {
            const cancel = await Services.CancelClass(
                "PUT",
                body,
                token,
                params.get("scheduleID")
            );
            setCancelModal(false);
            if (cancel?.Status == 1) {
                swal("Class Cancelled", "", "success");
                setLoading(false);
                getscheduledClasses();
            }
            if (cancel?.Status == 0) {
                swal(cancel.Message, "", "warning");
            }
        } catch (err) {
            setCancelModal(false);
            Error_Dark("something went wrong try again");
            console.log(err);
        }
    }
    const cancelHandler = (e) => {
        setCancelModal(true);
    };
    const backButton = () => {
        Navigate(-1);
    };
    const handleToggle = (rowId) => {
        const updatedRows = [...expandedRows];
        const index = updatedRows.indexOf(rowId);

        if (index === -1) {
            updatedRows.push(rowId);
        } else {
            updatedRows.splice(index, 1);
        }

        setExpandedRows(updatedRows);
    };
    const attendanceHandler = async (event) => {
        const Scheduled_Classes_StudentID =
            event.currentTarget.getAttribute("attr-studentId");
        const Attendance = event.target.value;
        let body = JSON.stringify({
            Attendance,
            Scheduled_Classes_StudentID,
            BatchName: data.BatchName,
        });
        try {
            setAttendenceLoad(true);
            const Response = await Services.MarkAttendace("POST", body, token);
            if (Response.Status === 1) {
                setAttendenceLoad(false);
                SuccessSwal("Success", Response.Message);
                let details = data;

                let index = details.Students?.findIndex(
                    (item) =>
                        // eslint-disable-next-line
                        item.Scheduled_Classes_StudentID ==
                        Scheduled_Classes_StudentID
                );
                details.Students[index].Attendance = Number(Attendance);
                setData(details);
            } else {
                setAttendenceLoad(false);
                Error(Response?.Message);
            }
        } catch (err) {
            console.log(err);
            console.log(data);
            setAttendenceLoad(false);
            Error("Something went wrong please try again");
        }
    };
    const reportHandler = (event) => {
        const batchStudentId =
            event.currentTarget.getAttribute("custom-studentid");
        setShowReport(batchStudentId);
    };
    const submithandler = async () => {
        let StartDateTime = moment(data?.Scheduled_StartDate).set({
            hour: parseInt(data?.Scheduled_StartTime.split(":")[0], 10),
            minute: parseInt(data?.Scheduled_StartTime.split(":")[1], 10),
        });
        console.log(
            StartDateTime.format("DD-MMM-YYYY HH:MM"),
            parseInt(data?.Scheduled_StartTime.split(":")[1], 10)
        );
        const wdnDateTime = StartDateTime.add(
            inputref.current.value,
            "minutes"
        );
        let body = {
            ClassDuration: inputref.current.value,
            ScheduleClassID: params.get("scheduleID"),
            Scheduled_StartDate: data?.Scheduled_StartDate,
            Scheduled_StartTime: data?.Scheduled_StartTime,
            Scheduled_EndDate: wdnDateTime.format("YYYY-MM-DD"),
            Scheduled_EndTime: wdnDateTime.format("HH:mm:ss"),
        };

        try {
            setAttendenceLoad(true);

            const Response = await Services.updateClassDuration(
                "POST",
                JSON.stringify(body),
                token
            );
            if (Response.Status === 1) {
                setEditClass(false);
                setAttendenceLoad(false);
                getscheduledClasses();
                SuccessSwal("Success", Response.Message);
            } else {
                setAttendenceLoad(false);
                Error(Response?.Message);
            }
        } catch (err) {
            console.log(err);
            console.log(data);
            setAttendenceLoad(false);
            Error("Something went wrong please try again");
        }
    };
    return (
        <>
            {AttendanceLoad && <Mainloader />}
            {ShowReport && (
                <Report
                    modal={ShowReport}
                    setModal={setShowReport}
                    getDetails={{
                        details: getscheduledClasses,
                        setLoading: setLoading,
                        data: data,
                    }}
                    id={params.get("scheduleID")}
                />
            )}
            {editclass && (
                <DurationEdit
                    modal={editclass}
                    setModal={setEditClass}
                    id={params.get("scheduleID")}
                    getDetails={{
                        details: getscheduledClasses,
                        setLoading: setLoading,
                        data: data,
                    }}
                />
            )}
            {completeClass && (
                <CompleteModal
                    modal={completeClass}
                    setModal={setCompleteClass}
                    getDetails={{
                        details: getscheduledClasses,
                        setLoading: setLoading,
                        data: data,
                    }}
                    id={params.get("scheduleID")}
                />
            )}
            <CancelClass
                completemodal={CancelModal}
                setModal={setCancelModal}
                data={data}
                cancelClass={cancelClassfunc}
            />
            {modal && (
                <Repeat
                    modal={modal}
                    setModal={setModal}
                    classdata={data}
                    details={getscheduledClasses}
                    setLoading={setLoading}
                />
            )}
            {rescheduleModal && (
                <Reschedule
                    setRescheduleModal={setRescheduleModal}
                    rescheduleModal={rescheduleModal}
                    data={{
                        Scheduled_StartDate: data?.Scheduled_StartDate,
                        id: params.get("scheduleID"),
                        Students: data?.Students,
                        Duration: data?.Duration,
                        ClassDuration: data?.ClassDuration,
                        CourseName: data?.CourseName,
                        ScheduleCreatedBy: data.ScheduleCreatedBy,
                        details: getscheduledClasses,
                        BatchID: data?.BatchID,
                        BatchName: data?.BatchName,
                        ZoomEmailID: data?.ZoomEmailID,
                        TutorID: data?.TutorID,
                        ZoomMeetingID: data?.ZoomMeetingID,
                        ScheduleClassID: data?.ScheduleClassID,
                        ScheduleCreatedByType: data?.ScheduleCreatedByType,
                    }}
                />
            )}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Class Details</title>
            </Helmet>
            <Layout Active={"scheduledClasses"}>
                {loading ? (
                    <Mainloader />
                ) : (
                    <div className={detailsClasses["container"]}>
                        <button
                            onClick={backButton}
                            className={detailsClasses["back"]}>
                            Back
                        </button>
                        <div className={detailsClasses["wrapper"]}>
                            <div className={detailsClasses["header-wrapper"]}>
                                <div className={detailsClasses["header"]}>
                                    <h3>
                                        <span></span>Name:
                                        {data.ClassLessonName}
                                    </h3>
                                    {data.ClassStatus == 1 &&
                                        data?.CancelRequestStatus != 1 &&
                                        AccessControl(
                                            AccessKeys["Scheduled Classes"]
                                        )?.edit === 1 && (
                                            <button
                                                type="0"
                                                onClick={cancelHandler}
                                                disabled={
                                                    data.ClassStatus == 2
                                                }>
                                                cancel
                                            </button>
                                        )}
                                    {AccessControl(
                                        AccessKeys["Scheduled Classes"]
                                    )?.edit === 1 &&
                                        data?.ClassStatus == 1 &&
                                        data?.CancelRequestStatus === 0 && (
                                            <button
                                                className={
                                                    detailsClasses["reschedule"]
                                                }
                                                onClick={() =>
                                                    setCompleteClass(true)
                                                }
                                                disabled={moment(
                                                    data?.Scheduled_StartDate
                                                ).isAfter(moment(), "day")}>
                                                Complete Class
                                            </button>
                                        )}
                                    {/* {data.ClassStatus == 1 &&
                                        AccessControl(
                                            AccessKeys["Scheduled Classes"]
                                        )?.edit === 1 && (
                                            <button
                                                type="1"
                                                onClick={cancelHandler}
                                                disabled={
                                                    data.ClassStatus == 2
                                                }>
                                                Tutor No Show
                                            </button>
                                        )} */}
                                    {/* //adminData.Type != 1 && */}
                                    {(data?.ReScheduleStatus == 0 ||
                                        data?.ReScheduleStatus == 1 ||
                                        data?.ReScheduleStatus === 3) &&
                                        data.ClassStatus == 1 &&
                                        data?.CancelRequestStatus != 1 &&
                                        AccessControl(
                                            AccessKeys["Scheduled Classes"]
                                        )?.edit === 1 && (
                                            <button
                                                className={
                                                    detailsClasses["reschedule"]
                                                }
                                                onClick={() =>
                                                    setRescheduleModal(true)
                                                }
                                                disabled={
                                                    data.ClassStatus == 2
                                                }>
                                                Reschedule
                                            </button>
                                        )}
                                    {data?.OrginalClassID === 0 &&
                                        data?.CancelRequestStatus != 1 &&
                                        AccessControl(
                                            AccessKeys["Scheduled Classes"]
                                        )?.edit === 1 && (
                                            <button
                                                className={
                                                    detailsClasses["reschedule"]
                                                }
                                                onClick={() => setModal(true)}>
                                                Repeat
                                            </button>
                                        )}
                                </div>
                                {data?.ReScheduleStatus === 1 &&
                                    data?.CancelRequestStatus === 0 &&
                                    data?.ClassStatus == 1 && (
                                        <p
                                            className={
                                                detailsClasses["rescheduled"]
                                            }>
                                            Rescheduled{" "}
                                        </p>
                                    )}
                                {data?.ReScheduleStatus === 2 &&
                                    data?.CancelRequestStatus === 0 &&
                                    data?.ClassStatus == 1 && (
                                        <p
                                            className={
                                                detailsClasses["requested"]
                                            }>
                                            Rescheduled Requested
                                        </p>
                                    )}
                                {data?.ReScheduleStatus === 3 &&
                                    data?.CancelRequestStatus === 0 &&
                                    data?.ClassStatus == 1 && (
                                        <p
                                            className={
                                                detailsClasses["rejected"]
                                            }>
                                            Rescheduled Request rejected
                                        </p>
                                    )}
                                {(data?.CancelRequestStatus === 1 ||
                                    data?.ClassStatus == 3) && (
                                    <p className={detailsClasses["rejected"]}>
                                        Cancelled
                                    </p>
                                )}
                                {data?.CancelRequestStatus === 2 && (
                                    <p className={detailsClasses["requested"]}>
                                        Cancel Requested
                                    </p>
                                )}
                                {data?.CancelRequestStatus === 3 && (
                                    <p className={detailsClasses["rejected"]}>
                                        Cancel rejected
                                    </p>
                                )}
                                {data.ClassStatus == 2 && (
                                    <p
                                        className={
                                            detailsClasses["rescheduled"]
                                        }>
                                        Completed{" "}
                                    </p>
                                )}
                            </div>
                            <div className={detailsClasses["profile"]}>
                                <div className={detailsClasses["internal"]}>
                                    <h3>
                                        Meeting Details
                                        <p
                                            style={{
                                                textDecoration: "underline",
                                                cursor: "pointer",
                                            }}
                                            onClick={() =>
                                                Navigate("/ActivityLog", {
                                                    state: {
                                                        Active: "scheduledClasses",
                                                        ActionByType: 10,
                                                        ActionFor:
                                                            params.get(
                                                                "scheduleID"
                                                            ),
                                                        name: data.ClassLessonName,
                                                        Page: "Classes",
                                                    },
                                                })
                                            }>
                                            (Activity Logs)
                                        </p>
                                    </h3>

                                    <div>
                                        <h3>Batch Name</h3>
                                        <p>
                                            <NavLink
                                                to={`/Batches/Details?BatchId=${data.BatchID}`}>
                                                {data.BatchName}
                                            </NavLink>
                                        </p>
                                    </div>
                                    <div>
                                        <h3>Course</h3>
                                        <p>{data.CourseName}</p>
                                    </div>
                                    {data.ClassStatus == 1 &&
                                        data?.CancelRequestStatus != 1 && (
                                            <div>
                                                <h3>Meeting Join Url</h3>
                                                <p>
                                                    {data.JoinUrl !== "NA" ? (
                                                        <a
                                                            href={data.JoinUrl}
                                                            target="_blank"
                                                            rel="noreferrer">
                                                            Link
                                                        </a>
                                                    ) : (
                                                        "NA"
                                                    )}
                                                </p>
                                            </div>
                                        )}
                                    <div>
                                        <h3>Start Date:</h3>
                                        <p>
                                            <Moment format="DD-MM-YYYY">
                                                {
                                                    new Date(
                                                        data.Scheduled_StartDate
                                                    )
                                                }
                                            </Moment>
                                        </p>
                                    </div>
                                    <div>
                                        <h3>Start Time:</h3>
                                        <p>
                                            {/* {UTC_TO_IST_TIME_CONVERSION(
                                                data.Scheduled_StartTime
                                            )} */}
                                            {data.Scheduled_StartTime}
                                        </p>
                                    </div>
                                    <div>
                                        <h3>End Time:</h3>
                                        <p>
                                            {/* {UTC_TO_IST_TIME_CONVERSION(
                                                data.Scheduled_EndTime
                                            )} */}
                                            {data.Scheduled_EndTime}
                                        </p>
                                    </div>
                                    <div>
                                        <h3>Join:</h3>
                                        <p>
                                            <a
                                                href={data?.MagicLink}
                                                target="_blank">
                                                Join
                                            </a>
                                        </p>
                                    </div>
                                    {/*  */}
                                </div>
                                <div
                                    className={detailsClasses["internal"]}
                                    style={{
                                        borderLeft: "1px solid #ccc",
                                        paddingLeft: "0.5rem",
                                    }}>
                                    <h3></h3>
                                    <div>
                                        <h3>Tutor</h3>

                                        <p>
                                            <NavLink
                                                to={`/Tutor/Details?TutorId=${data.TutorID}`}>
                                                {data?.TutorName}
                                            </NavLink>
                                        </p>
                                    </div>
                                    <div>
                                        <h3>Tutor Mail</h3>
                                        <h3>{data.TutorEmailID}</h3>
                                    </div>
                                    <div>
                                        <h3>Tutor Mobile</h3>
                                        <h3>{data.TutorMobile}</h3>
                                    </div>
                                    {data?.ClassStatus == 2 && (
                                        <div>
                                            <h3>Lesson Plan</h3>
                                            <p>
                                                {data?.LessonPlans_Class.join(
                                                    ","
                                                )}
                                                {data.PartiallyCompleted
                                                    .IsPartiallyCompleted ===
                                                    1 &&
                                                    `,${data?.PartiallyCompleted.LessonPlan}(Slide:${data?.PartiallyCompleted.Slide})`}
                                            </p>
                                        </div>
                                    )}
                                    <div>
                                        <h3>Duration</h3>
                                        <p>
                                            {data?.ClassDuration} Min{" "}
                                            {data.ClassStatus == 1 &&
                                                data?.CancelRequestStatus !=
                                                    1 && (
                                                    <button
                                                        onClick={() =>
                                                            setEditClass(true)
                                                        }>
                                                        <MdEdit />
                                                    </button>
                                                )}
                                        </p>
                                    </div>
                                    <div>
                                        <h3>Schedule Created By</h3>
                                        <p>
                                            {" "}
                                            <NavLink
                                                to={`/Administrators/details?ID=${data.ScheduleCreatedBy}`}>
                                                {data?.ScheduleCreated}
                                            </NavLink>
                                        </p>
                                    </div>
                                    {data.ClassStatus == 2 && (
                                        <div>
                                            <h3>Comments</h3>
                                            <p> {data.Comments}</p>
                                        </div>
                                    )}
                                    {data.ClassStatus == 2 && (
                                        <div>
                                            <h3>Topics Done</h3>
                                            <p> {data.NewTopicDone}</p>
                                        </div>
                                    )}

                                    {data.ClassStatus == 3 ||
                                        (data?.CancelRequestStatus == 1 && (
                                            <div>
                                                <h3>Cancel Reason</h3>
                                                <p>{data?.CancelReason}</p>
                                            </div>
                                        ))}
                                    {data.ClassStatus == 3 ||
                                        (data?.CancelRequestStatus == 1 && (
                                            <div>
                                                <h3>Cancelled ON</h3>
                                                <p>
                                                    {" "}
                                                    <Moment
                                                        format="hh:mm a Do MMM YYYY "
                                                        utc>
                                                        {data.CancelledOn}
                                                    </Moment>
                                                </p>
                                            </div>
                                        ))}
                                </div>
                            </div>

                            <div className={detailsClasses["Table"]}>
                                <h3>Meeting details</h3>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Download URL</th>
                                            <th>Play URL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.ZoomRestartData &&
                                        data?.ZoomRestartData.length > 0 ? (
                                            data?.ZoomRestartData.map(
                                                (item) => (
                                                    <tr>
                                                        <td>
                                                            <a
                                                                target="_blank"
                                                                href={
                                                                    item?.DownloadUrl
                                                                }>
                                                                Download
                                                            </a>
                                                            {/* {item?.} */}
                                                        </td>
                                                        <td>
                                                            {/* {item?.}{" "} */}
                                                            <a
                                                                target="_blank"
                                                                href={
                                                                    item?.PlayURL
                                                                }>
                                                                Play
                                                            </a>
                                                        </td>
                                                    </tr>
                                                )
                                            )
                                        ) : data?.DownloadUrl != "NA" &&
                                          data?.PlayURL != "NA" ? (
                                            <tr>
                                                <td>
                                                    <a href={data?.DownloadUrl}>
                                                        Download
                                                    </a>{" "}
                                                </td>
                                                <td>
                                                    <a href={data?.PlayURL}>
                                                        Play
                                                    </a>
                                                </td>
                                            </tr>
                                        ) : (
                                            <tr>
                                                <td colSpan={2}>
                                                    No Record Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className={detailsClasses["Table"]}>
                                <h3>Student Details</h3>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Student Name</th>
                                            <th>Parent Name</th>
                                            {data?.ClassStatus == 1 &&
                                                data?.CancelRequestStatus !=
                                                    1 &&
                                                data?.BatchSize === "Group" && (
                                                    <th>Attendance</th>
                                                )}
                                            <th>Report</th>
                                            <th>Review</th>
                                            <th>Rating</th>
                                            {data.ClassStatus == 2 &&
                                                data.BatchSize === "Group" && (
                                                    <th>Attendance</th>
                                                )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.Students?.map((item) => {
                                            return (
                                                <tr>
                                                    <td>{item.StudentName}</td>
                                                    <td>
                                                        <NavLink
                                                            to={`/parent/Details?Id=${item.ParentID}`}>
                                                            {item.ParentName}
                                                        </NavLink>
                                                    </td>
                                                    {data?.ClassStatus == 1 &&
                                                        data?.CancelRequestStatus !=
                                                            1 &&
                                                        data?.BatchSize ===
                                                            "Group" && (
                                                            <td>
                                                                <select
                                                                    className={
                                                                        detailsClasses[
                                                                            "select-dropdown"
                                                                        ]
                                                                    }
                                                                    defaultValue={
                                                                        item.Attendance
                                                                    }
                                                                    // disabled={
                                                                    //     item.Attendance !==
                                                                    //     0
                                                                    // }
                                                                    attr-studentId={
                                                                        item.Scheduled_Classes_StudentID
                                                                    }
                                                                    onChange={
                                                                        attendanceHandler
                                                                    }
                                                                    disabled={
                                                                        moment(
                                                                            data?.Scheduled_StartDate
                                                                        ).isAfter(
                                                                            moment(),
                                                                            "day"
                                                                        ) ||
                                                                        item.Attendance !==
                                                                            0 ||
                                                                        data.CancelRequestStatus ===
                                                                            1
                                                                    }>
                                                                    <option
                                                                        value={
                                                                            0
                                                                        }
                                                                        disabled>
                                                                        Mark
                                                                        Attendance
                                                                    </option>
                                                                    <option value="1">
                                                                        Present
                                                                    </option>
                                                                    <option value="2">
                                                                        Absent{" "}
                                                                    </option>
                                                                    <option value="3">
                                                                        Absent
                                                                        but not
                                                                        charged
                                                                    </option>
                                                                </select>
                                                            </td>
                                                        )}
                                                    <td>
                                                        <button
                                                            className={`${
                                                                item.IsReportSubmitted ===
                                                                1
                                                                    ? detailsClasses[
                                                                          "report-edit"
                                                                      ]
                                                                    : detailsClasses[
                                                                          "report"
                                                                      ]
                                                            }`}
                                                            custom-studentid={
                                                                item.Scheduled_Classes_StudentID
                                                            }
                                                            disabled={
                                                                data.CancelRequestStatus ===
                                                                    1 ||
                                                                moment(
                                                                    data?.Scheduled_StartDate
                                                                ).isAfter(
                                                                    moment(),
                                                                    "day"
                                                                )
                                                            }
                                                            onClick={
                                                                reportHandler
                                                            }>
                                                            {item.IsReportSubmitted ===
                                                            1 ? (
                                                                <span>
                                                                    Reported
                                                                    <FaEdit />
                                                                </span>
                                                            ) : (
                                                                "Report"
                                                            )}
                                                        </button>
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: "250px",
                                                            textAlign:
                                                                "justify",
                                                        }}>
                                                        {item.Review !=
                                                            "NA" && (
                                                            <div
                                                                className={
                                                                    detailsClasses[
                                                                        "content-wrapper"
                                                                    ]
                                                                }>
                                                                <div
                                                                    className={`${
                                                                        detailsClasses[
                                                                            "content"
                                                                        ]
                                                                    } ${
                                                                        expandedRows.includes(
                                                                            item.StudentID
                                                                        )
                                                                            ? detailsClasses[
                                                                                  "expanded"
                                                                              ]
                                                                            : ""
                                                                    }`}>
                                                                    {item.Review.split(
                                                                        "\n"
                                                                    ).map(
                                                                        (
                                                                            line,
                                                                            index
                                                                        ) => (
                                                                            <div
                                                                                key={
                                                                                    index
                                                                                }>
                                                                                {
                                                                                    line
                                                                                }
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                                <button
                                                                    onClick={() =>
                                                                        handleToggle(
                                                                            item.StudentID
                                                                        )
                                                                    }
                                                                    className={
                                                                        detailsClasses[
                                                                            "expand-btn"
                                                                        ]
                                                                    }>
                                                                    {expandedRows.includes(
                                                                        item.StudentID
                                                                    )
                                                                        ? "Show less"
                                                                        : "Show more"}
                                                                </button>
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td>{item.Rating}</td>
                                                    {data.ClassStatus == 2 &&
                                                        data.BatchSize ===
                                                            "Group" && (
                                                            <td>
                                                                {item.Attendance ==
                                                                1 ? (
                                                                    <span
                                                                        className={
                                                                            detailsClasses[
                                                                                "completed"
                                                                            ]
                                                                        }>
                                                                        Present
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className={
                                                                            detailsClasses[
                                                                                "cancelled"
                                                                            ]
                                                                        }>
                                                                        Absent
                                                                    </span>
                                                                )}
                                                            </td>
                                                        )}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className={detailsClasses["Notifications"]}>
                                <h3>Notifications</h3>
                                {notifications.load ? (
                                    <Skeleton count={3} height={"10px"} />
                                ) : (
                                    <div
                                        className={
                                            detailsClasses["notifyData"]
                                        }>
                                        {notifications.notifyData.length > 0 ? (
                                            <ul>
                                                {notifications.notifyData.map(
                                                    (item) => (
                                                        <li>{item.Message}</li>
                                                    )
                                                )}
                                            </ul>
                                        ) : (
                                            <p>No New Notification</p>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </Layout>
        </>
    );
}

export default Details;
